// components.scss

.reply-indicator__content a {
  color: $ui-highlight-color;
}

.attachment-list {
  &__icon {
    color: $ui-highlight-color
  }

  &__list {
    a {
      color: $ui-highlight-color
    }
  }
}

.search__input {
border-radius: 50px;
}
